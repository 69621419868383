import React from 'react';
import { Link } from 'react-router-dom';
import './blog.scss';
import moment from 'moment/moment';
import { formatMonth } from '../../data/format-month';

function Article(props) {
  const language = localStorage.getItem('i18nextLng')
  const formatDate = (date) => {
    const month = date.split("T")[0].split("-")[1]
    return `${formatMonth(month, language)} ${moment(date).format(`DD, YYYY, HH:mm`)}`
  }

  return (
    <Link to={{ pathname: `/news-details/${props.id}` }} >
      <div className={`blog-post article-post blog-elements ${'' || ''}`}>
        <div className="post-image list">
          <img
            className=" img-fluid"
            src={`${props.imgSrc}`}
            alt={props.title}
          />
        </div>
        <div className="entry-content">
          <div className="entry-meta">
            <ul>
              <li key="meta-category">
                <img src='/images/calendar.svg' className='' alt='' />
              </li>
              <li key="meta-date" className="entry-meta-date">
                <div>{formatDate(props.date)}</div>
              </li>
            </ul>
          </div>
          <h4 className="entry-title">
            <Link to={{ pathname: `/news-details/${props.id}` }}>
              {props.title}
            </Link>
          </h4>
          {/* {props?.description && (
            <div className="entry-description">
              <p>{props.description}</p>
            </div>
          )} */}

          {/* <div className='entry-button'>
            <Link to={{ pathname: `/article-details/${props.id}` }} className='entry-button-link font-rubik'>{t("read_full")}</Link>
          </div> */}
        </div>
      </div>
    </Link>
  );
}

export default Article;
